// JS Goes here - ES6 supported
import "./scss/styles.scss";

import $ from "jquery";
import moment from "moment";
import Pikaday from "pikaday";

import "./js/util.js";
import "./js/carousel.js";
import "./js/featherlight.min.js";
import "../node_modules/bootstrap/js/dist/dropdown.js";
import AOS from "../node_modules/aos/dist/aos.js";

window.toggleNav = function() {
  $("#overlayNav").toggleClass("active");
};

window.toggleBooking = function() {
  $("#bookingOverlay").toggleClass("active");
};

$(window).scroll(function() {
  var scrollPosition = window.scrollY,
    $header = $(".header");

  if (scrollPosition >= 100) {
    $header.addClass("fixed");

    if (scrollPosition >= 150) {
      $header.addClass("shown");
    } else {
      $header.removeClass("shown");
    }
  } else {
    $header.removeClass("fixed");
    $header.removeClass("shown");
  }
});

$(".block-view-more__link").click(function(event) {
  $(this).parent().toggleClass("active");
  $(this).children(".view-inactive").toggleClass("hide");
  $(this).children(".view-active").toggleClass("hide");
});


// Booking
var currentDate = new Date();
var nextDate = new Date(moment(currentDate).add(1, "days").format("Y-MM-DD"));

var toPicker = new Pikaday({
  field: document.getElementById("toDate"),
  minDate: nextDate,
  yearRange: [currentDate.getFullYear(), currentDate.getFullYear() + 1],
  format: "ddd, DD MMM Y",
  defaultDate: nextDate,
  setDefaultDate: true,
  onSelect: function() {
    var toDate = this.getDate();

    $("#AVP_list_ddays").val(toDate.getDate());
    $("#AVP_list_dmonths").val(toDate.getFullYear() + "-" + (toDate.getMonth() + 1));

    this.setEndRange(toDate);
    fromPicker.setEndRange(toDate);
  },
  onDraw: function() {
    var monthSelected = $(".pika-label:first-child :selected").val();
    var yearSelected = $(".pika-label:nth-child(2) :selected").val();
    monthSelected = parseInt(monthSelected) + 1;

    $("#AVP_list_dmonths").val(
      yearSelected + "-" + monthSelected
    );
    setDDays();
  },
  i18n: {
    previousMonth : "Previous Month",
    nextMonth     : "Next Month",
    months        : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    weekdays      : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    weekdaysShort : ["SU", "MO", "TU", "WE", "TH", "FR", "SA"]
  }
});

var fromPicker = new Pikaday({
  field: document.getElementById("fromDate"),
  minDate: currentDate,
  yearRange: [currentDate.getFullYear(), currentDate.getFullYear() + 1],
  format: "ddd, DD MMM Y",
  defaultDate: currentDate,
  setDefaultDate: true,
  onSelect: function() {
    var fromDate = this.getDate();

    var fromDay = fromDate.getDate();
    var fromMonth = fromDate.getMonth() + 1;
    var fromYear = fromDate.getFullYear();

    var tomorrowDate =  moment(fromDate).add(1, "days").format("Y-MM-DD");
    tomorrowDate = new Date(tomorrowDate);

    $("#AVP_list_days").val(fromDay);
    $("#AVP_list_months").val(fromYear + "-" + fromMonth);

    if (this.getDate().valueOf() >= toPicker.getDate().valueOf()) {
      toPicker.setDate(tomorrowDate);

      $("#AVP_list_ddays").val(tomorrowDate.getDate());
      $("#AVP_list_dmonths").val(
        tomorrowDate.getFullYear() + "-" + (tomorrowDate.getMonth() + 1)
      );
    }

    toPicker.setMinDate(fromDate);
    toPicker.setStartRange(fromDate);

    this.setStartRange(fromDate);

    updateDDay();
  },
  onDraw: function() {
    var monthSelected = $(".pika-label:first-child :selected").val();
    var yearSelected = $(".pika-label:nth-child(2) :selected").val();
    monthSelected = parseInt(monthSelected) + 1;

    $("#AVP_list_months").val(yearSelected + "-" + monthSelected);
    setDays();
  },
  i18n: {
    previousMonth : "Previous Month",
    nextMonth     : "Next Month",
    months        : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    weekdays      : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    weekdaysShort : ["SU", "MO", "TU", "WE", "TH", "FR", "SA"]
  }
});

fromPicker.setStartRange(currentDate);
fromPicker.setEndRange(nextDate);

// Animate
AOS.init({
  duration: 1000,
  easing: "ease",
  delay: 100,
  disable: "mobile",
  once: true
});
